const enTable = {
    'columns': {
        'kw': {
            'title': 'Keyword',
            'tooltip': '',
        },
        'url': {
            'title': 'URL',
            'tooltip': '',
        },
        'start': {
            'title': 'Start',
            'tooltip': 'This is the ranking recorded when the keyword was first added in. You can click on the number to edit the value',
        },
        'gpage': {
            'title': 'Page',
            'tooltip': 'This is the page the keyword is ranking on',
        },
        'best': {
            'title': 'Best',
            'tooltip': 'The all-time best ranking position since date added.',
        },
        'grank': {
            'title': 'Rank',
            'tooltip': 'The current rank of Keyword.',
        },
        'serp_features': {
            'title': 'SERP Features',
            'tooltip': '',
        },
        'day': {
            'title': '1d',
            'tooltip': 'Indicates the rank position change of Keyword in the last 1 day.',
        },
        'week': {
            'title': '7d',
            'tooltip': 'Indicates the rank position change of Keyword in the last 7 days.',
        },
        'month': {
            'title': '30d',
            'tooltip': 'Indicates the rank position change of Keyword in the last 30 days.',
        },
        'life': {
            'title': 'Life',
            'tooltip': 'Indicates the rank position change of Keyword from date added.',
        },
        'clicks': {
            'title': 'Clicks',
            'tooltip': 'The number of clicks from a Google search result that landed the user on your property.',
        },
        'impressions': {
            'title': 'Impressions',
            'tooltip': 'How many links to your site a user saw on Google search results.',
        },
        'ctr': {
            'title': 'CTR',
            'tooltip': 'Click-through rate: the click count divided by the impression count.',
        },
        'searchvolume': {
            'title': 'Search Vol.',
            'tooltip': 'Monthly search volume for Keyword relevant to tracked region.',
        },
        'cpc': {
            'title': 'CPC',
            'tooltip': 'Average Cost Per Click.',
        },
        'competition': {
            'title': 'Competition',
            'tooltip': 'Competition index indicates how many advertisers are bidding for this keyword. Metrics explained: 0-34 (Low competition), 35-69 (Medium competition), 70-100 (high competition).',
        },
        'seocomp': {
            'title': 'Search Results',
            'tooltip': 'The number of indexed pages by Google.',
        },
        'tagsSplited': {
            'title': 'Tags',
            'tooltip': 'Tags associated to the keyword. You can add tags to segment your keywords',
        },
        'timestamp': {
            'title': 'Date Added',
            'tooltip': 'Date the Keyword was added to the Project (YYYY-MM-DD).',
        },
        'near': {
            'title': 'Search Location',
            'tooltip': 'If specified, this is the location we are searching from when updating the keyword',
        },
        'gmb': {
            'title': 'GMB',
            'tooltip': 'Google My Business name (for local keywords) we are also tracking',
        },
        'source': {
            'title': 'Source',
            'tooltip': '',
        },
        'search_intents': {
            'title': 'Search Intent',
            'tooltip': '',
        },
        'keyword_last_updated': {
            'title': 'Keyword Last Updated',
            'tooltip': 'Keyword Last Updated',
        },
        'visibility': {
            'title': 'Visibility',
            'tooltip': '',
        },
        'estimated_traffic': {
            'title': 'Estimated Traffic',
            'tooltip': '',
        }
    },
    'i-best': 'The all-time best ranking position since date added.',
    'i-day': 'Indicates the rank position change of Keyword in the last 1 day.',
    'i-gmb': 'Google My Business name (for local keywords) we are also tracking',
    'i-gpage': 'This is the page the keyword is ranking on',
    'i-grank': 'The current rank of Keyword.',
    'i-life': 'Indicates the rank position change of Keyword from date added.',
    'i-month': 'Indicates the rank position change of Keyword in the last 30 days.',
    'i-near': 'If specified, this is the location we are searching from when updating the keyword',
    'i-searchvolume': 'Monthly search volume for Keyword relevant to tracked region.',
    'i-cpc': 'Average Cost Per Click.',
    'i-competition': 'Competition index indicates how many advertisers are bidding for this keyword. Metrics explained: 0-34 (Low competition), 35-69 (Medium competition), 70-100 (high competition).',
    'i-seocomp': 'The number of indexed pages by Google.',
    'i-start': 'This is the ranking recorded when the keyword was first added in. You can click on the number to edit the value',
    'i-tagsSplited': 'Tags associated to the keyword. You can add tags to segment your keywords',
    'i-timestamp': 'Date the Keyword was added to the Project (YYYY-MM-DD).',
    'i-week': 'Indicates the rank position change of Keyword in the last 7 days.',
    'i-clicks': 'The number of clicks from a Google search result that landed the user on your property.',
    'i-impressions': 'How many links to your site a user saw on Google search results.',
    'i-ctr': 'Click-through rate: the click count divided by the impression count.',
    'i-visibility': 'Visibility.',
    'i-estimated_traffic': 'Estimated Traffic.',
    'i-keyword_last_updated': 'Keyword Last Updated',
    'se-ranking': 'Desktop',
    'see-in-search-results': 'See the keyword in search results',
    'sem-ranking': 'Mobile',
    'view-charts': 'Show Historical Chart',
    'refresh-state-tooltip': 'Keyword update in progress',
    'not-changed-data': 'No change in data',
    'data-not-available': 'Data is not available yet',
    'only-for-google': 'This data is only available for Google Desktop keywords',
    'refreshing': 'Refreshing, contact support after 72 hours',
    'not-supported-search-intents': 'Search Intent functionality is currently unavailable for this language. Stay tuned for updates.',
    'low': "Low",
    'medium': "Medium",
    'high': "High",
    'serp-feature-adwords-bottom': 'Adwords Bottom',
    'serp-feature-adwords-top': 'Adwords Top',
    'serp-feature-ads': 'Ads',
    'serp-feature-carousel': 'Carousel',
    'serp-feature-coupon_codes': 'Coupon Code',
    'serp-feature-faq': 'FAQ',
    'serp-feature-featured_snippet': 'Featured Snippet',
    'serp-feature-image_pack': 'Image Pack',
    'serp-feature-indeed-url': 'Indeed URL',
    'serp-feature-knowledge_panel': 'Knowledge Panel',
    'serp-feature-local_pack': 'Local Pack',
    'serp-feature-ppl_also_ask': 'People Also Ask',
    'serp-feature-reviews': 'Reviews',
    'serp-feature-shopping_results': 'Shopping Results',
    'serp-feature-site_links': 'Site Links',
    'serp-feature-top_stories': 'Top Stories',
    'serp-feature-tweets': 'X',
    'serp-feature-video': 'Video',
    'updating': "Updating",
    'serp-feature-recipes': 'Recipes',
    'serp-feature-top_sights': 'Top Sights',
    'serp-feature-ai': '<span><b>AI Overview</b></span>',
    'serp-feature-ai-with-url': '<div><b>AI Overview</b><br> <span>Source url:</span> <b><a target="_blank" href="{url}">{url}</a></b></div>',
    'search-intents' : {
        'commercial': 'Commercial',
        'informational': 'Informational',
        'navigational': 'Navigational',
        'transactional': 'Transactional',
    },
};

export default enTable;
